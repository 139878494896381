<template>
	<ajax-form-modal v-model="isActive"
					 ref="formModalComponent"
					 :form-options="{action: props.action, method: 'GET'}"
					 :modal-options="{title: t('action.add_to_calendar')}"
					 hide-submit
					 @success="handleInitializedDownload" @error="handleFailedDownload">

		<add-btn v-for="type in downloadTypes" :key="type.value" flat outlined class="flex-grow-1 my-8"
				 :image="type.image"
				 :icon="type.icon"
				 :text="type.title"
				 :help="type.description"
				 :disabled="selectedDownloadType"
				 @click="selectDownloadType(type)"/>
	</ajax-form-modal>
</template>

<script setup lang="ts">
import {mdiContentCopy} from '@mdi/js';
import {downloadIcon} from "@spoferan/nuxt-spoferan/icons";

const props = defineProps<{
	modelValue?: boolean,
	action: string;
	analyticsType?: string;
}>();

const emit = defineEmits(['update:modelValue']);

const {isActive} = useToggleableComponent(props, emit);

const {t} = useI18n();
const {$analytics, $downloadFile, $notify} = useNuxtApp();

const downloadTypes = [
	{
		value: 'google',
		title: t('enum.calendar_download_type.google.title'),
		description: t('enum.calendar_download_type.google.description'),
		image: '/logos/google-calendar.svg',
		action: (downloadUrl) => {
			const webcalUrl = downloadUrl.replace('https://', 'webcal://').replace('http://', 'webcal://')
			const googleImportUrl = `https://www.google.com/calendar/render?cid=${webcalUrl}`
			window.open(googleImportUrl, '_blank');
		}
	},
	{
		value: 'copy_url',
		title: t('enum.calendar_download_type.copy_url.title'),
		description: t('enum.calendar_download_type.copy_url.description'),
		icon: mdiContentCopy,
		action: (downloadUrl) => {
			const webcalUrl = downloadUrl.replace('https://', 'webcal://').replace('http://', 'webcal://')
			navigator.clipboard.writeText(webcalUrl);
			$notify('success', t('text.text_copied_to_clipboard', {text: webcalUrl}))
		}
	},
	{
		value: 'download',
		title: t('enum.calendar_download_type.download.title'),
		description: t('enum.calendar_download_type.download.description'),
		icon: downloadIcon,
		action: (downloadUrl) => {
			$downloadFile(downloadUrl);
		}
	},
];

const selectedDownloadType = ref(null);
const formModalComponent = ref(null);

function selectDownloadType(type) {
	selectedDownloadType.value = type;
	nextTick(() => {
		formModalComponent.value.validationSubmit();
	})
}

function handleInitializedDownload(downloadUrl) {
	if (props.analyticsType) {
		$analytics.trackEvent('download', {model_type_name: props.analyticsType,
			url: downloadUrl
		});
	}

	selectedDownloadType.value.action(downloadUrl);
	selectedDownloadType.value = null;
}

function handleFailedDownload() {
	selectedDownloadType.value = null;
}

</script>